import React, { useState, useEffect } from "react";
import * as css from "./style.module.scss";

import EnrollmentMasterPage from "./components/enrollment-master-page";
import AdditionalInfo from "./components/enrollment-additional-info";

import EligibiltyQuestions from "./components/enrollment-eligibility-questions";
import Checkout from "./components/enrollment-checkout";
import EnrollmentFinalStep from "../enrollment-final-step";

import ConfirmApplication from "./components/enrollment-confirm-application";
import ThankYou from "./components/enrollment-thank-you";

import Lottie from "react-lottie-player";
import lottieJson from "./loading.json";
import { Container, Icon } from "..";

import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

new Promise((resolve) => setTimeout(resolve, 3000));

const EnrollmentPlatformRaw = ({ data, setApplicationsUniversal }) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const params = JSON.parse(localStorage.getItem("pivot_params"));
      const spouse = params.spouse ? JSON.parse(params.spouse) : null;
      const dependents = params.dependents
        ? JSON.parse(JSON.parse(params.dependents))
        : null;
      const monthlyValue = JSON.parse(
        localStorage.getItem("pivot_plans")
      ).reduce((a, b) => {
        return a + b.premium.value;
      }, 0);
      setApplicationsUniversal({
        spouse,
        dependents,
        monthlyValue,
        applications: [],
      });

      setLoading(false);
    }, 1000);
  }, []);

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: 300, height: 300 }}
        />

        <Container style={{ textAlign: "center" }}>Enrollment Tool</Container>
      </Container>
    </Container>
  ) : currentStep < 3 ? (
    <EnrollmentMasterPage
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      data={data}
    >
      {currentStep == 0 && (
        <EligibiltyQuestions
          setLoading={setLoading}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          next={() => {
            setCurrentStep(1);
          }}
        />
      )}

      {currentStep == 1 && (
        <AdditionalInfo
          previous={() => {
            setCurrentStep(0);
          }}
          next={() => {
            setCurrentStep(2);
          }}
        />
      )}

      {currentStep == 2 && (
        <Checkout
          setCurrentStep={setCurrentStep}
          next={() => {
            setCurrentStep(3);
          }}
        />
      )}
    </EnrollmentMasterPage>
  ) : currentStep == 3 ? (
    <EnrollmentFinalStep
      description=" "
      subtext="You will shortly receive an email with more information about your policy."
    />
  ) : (
    <ThankYou />
  );
};

const mapStateToProps = ({ agentMetadata, baseUrl, enrollment, quoteId }) => {
  return {
    agentMetadata,
    baseUrl,
    quoteId,
    enrollment: { ...enrollment },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApplicationsUniversal: (payload) =>
      dispatch({ type: `SET_APPLICATIONS`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrollmentPlatformRaw);
