// extracted by mini-css-extract-plugin
export var aboutContainer = "style-module--about-container--fL0gS";
export var allowed = "style-module--allowed--C8kgo";
export var blocked = "style-module--blocked--huoOj";
export var btnNoToAll = "style-module--btn-no-to-all--OKpMH";
export var categoryContainer = "style-module--category-container--J6ZGl";
export var clickSign = "style-module--click-sign--epEea";
export var disclaimerContainer = "style-module--disclaimer-container--rykRF";
export var eligibilityQuestion = "style-module--eligibility-question--zwFAW";
export var eligibilityQuestionError = "style-module--eligibility-question-error--32WdJ";
export var eligibilityQuestionIndex = "style-module--eligibility-question-index--l121K";
export var eligibilityQuestionOk = "style-module--eligibility-question-ok--nS9SZ";
export var eligibilityQuestionRadio = "style-module--eligibility-question-radio--szaMx";
export var eligibilityQuestionText = "style-module--eligibility-question-text--Xs6KW";
export var eligibilityQuestionsContainer = "style-module--eligibility-questions-container--26AmI";
export var eligibilityQuestionsInnerContainer = "style-module--eligibility-questions-inner-container--4odsl";
export var eligibilityQuestionsTitle = "style-module--eligibility-questions-title--nAuFl";
export var nextButton = "style-module--next-button--UhzuH";
export var noButton = "style-module--no-button--PtsHq";
export var radio = "style-module--radio---dH5E";
export var signatureGrid = "style-module--signature-grid--Yw75b";
export var signatureInput = "style-module--signature-input--9-mEg";
export var writableSign = "style-module--writable-sign--YPeO1";