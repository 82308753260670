import React, { useState } from "react";

import {
  Container,
  Icon,
  ProductCard,
  ProductCardList,
  Tooltip,
} from "../../..";

import { OfferCard } from "../../../quotes-panel/components";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/lottie.json";
import { getJSDocParameterTags } from "typescript";
import { connect } from "react-redux";
import {
  calculateAgeBasedOnDob,
  formatCurrency,
  getIconPropsForPlan,
} from "../../../../utils";

let sjson;
if (typeof window !== "undefined") {
  if (window.matchMedia("(max-width: 780px)").matches) {
    sjson = {
      AL: "AL",
      AK: "AK",
      AS: "AS",
      AZ: "AZ",
      AR: "AR",
      CA: "CA",
      CO: "CO",
      CT: "CT",
      DE: "DE",
      DC: "DC",
      FM: "FM",
      FL: "FL",
      GA: "GA",
      GU: "GU",
      HI: "HI",
      ID: "ID",
      IL: "IL",
      IN: "IN",
      IA: "IA",
      KS: "KS",
      KY: "KY",
      LA: "LA",
      ME: "ME",
      MH: "MH",
      MD: "MD",
      MA: "MA",
      MI: "MI",
      MN: "MN",
      MS: "MS",
      MO: "MO",
      MT: "MT",
      NE: "NE",
      NV: "NV",
      NH: "NH",
      NJ: "NJ",
      NM: "NM",
      NY: "NY",
      NC: "NC",
      ND: "ND",
      MP: "MP",
      OH: "OH",
      OK: "OK",
      OR: "OR",
      PW: "PW",
      PA: "PA",
      PR: "PR",
      RI: "RI",
      SC: "SC",
      SD: "SD",
      TN: "TN",
      TX: "TX",
      UT: "UT",
      VT: "VT",
      VI: "VI",
      VA: "VA",
      WA: "WA",
      WV: "WV",
      WI: "WI",
      WY: "WY",
    };
  } else {
    sjson = {
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FM: "Federated States Of Micronesia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MH: "Marshall Islands",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      MP: "Northern Mariana Islands",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PW: "Palau",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VI: "Virgin Islands",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    };
  }
}

const EnrollmentSummary = (props) => {
  const params = JSON.parse(localStorage.getItem("pivot_params"));
  const plans = JSON.parse(localStorage.getItem("pivot_plans"));
  const isChildOnly = params?.age < 18;
  const pivotSelectedPlans = JSON.parse(
    localStorage.getItem("pivot-selected-plans")
  ).filter((plan) => plans.some((p) => p.plan_id.value === plan.plan_id));
  const montlyContribution = pivotSelectedPlans.reduce((a, b) => {
    return a + Number(b.display_info.monthlyCost);
  }, 0);
  const monthlyFees = pivotSelectedPlans.reduce(
    (acc, plan) =>
      plan.fees && plan.fees.MONTHLY?.total
        ? acc + plan.fees.MONTHLY.total
        : acc,
    0
  );
  const oneTimeFees = pivotSelectedPlans.reduce(
    (acc, plan) =>
      plan.fees && plan.fees.ONE_TIME?.total
        ? acc + plan.fees.ONE_TIME.total
        : acc,
    0
  );
  const monthlyFeesComponent = pivotSelectedPlans.map(
    (plan) =>
      plan.fees &&
      plan.fees.MONTHLY && (
        <Container className={css.tooltip}>
          <Container>
            <strong>{plan.display_info.name}</strong>
          </Container>
          {plan.fees.MONTHLY &&
            plan.fees.MONTHLY.fees_details.map((fee) => (
              <Container className={css.fee}>
                {fee.label} - {formatCurrency(fee.value)}
              </Container>
            ))}
        </Container>
      )
  );
  const oneTimeFeesComponent = pivotSelectedPlans.map(
    (plan) =>
      plan.fees &&
      plan.fees.ONE_TIME && (
        <Container className={css.tooltip}>
          <Container>
            <strong>{plan.display_info.name}</strong>
          </Container>
          {plan.fees.ONE_TIME &&
            plan.fees.ONE_TIME.fees_details.map((fee) => (
              <Container className={css.fee}>
                {fee.label} - {formatCurrency(fee.value)}
              </Container>
            ))}
        </Container>
      )
  );

  return (
    <Container className={css.summaryContainer}>
      <Container className={``}>
        <Container className={css.selectedProductsAndPersonalInfo}>
          <Container className={css.personalInfoContainer}>
            <Container>
              <Container className={css.checkoutTitle}>
                Review and Complete your Application:
              </Container>
              <Container className={css.iconAndSummary}>
                <Container className={css.icon}>
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 240, height: 240 }}
                  />
                </Container>
              </Container>
            </Container>
            <Container className={css.personalInfoCard}>
              <Container className={css.personalInfoTitle}>
                Personal Info:
              </Container>
              <Container className={css.personalInfoValues}>
                <Container className={css.personalInfoValue}>
                  <span>Name: </span>
                  {params.firstName || "John"} {params.lastName || "Diego"}
                </Container>
                {params.date_of_birth ? (
                  <Container className={css.personalInfoValue}>
                    <span>Age: </span>
                    {calculateAgeBasedOnDob(params.date_of_birth) || "-"}
                  </Container>
                ) : null}
                <Container className={css.personalInfoValue}>
                  <span>Gender: </span>
                  {params.gender || "Male"}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Tobacco: </span>
                  {params.usesTobacco ? "Yes" : "No"}
                </Container>

                <Container className={css.personalInfoValue}>
                  <span>State: </span>
                  {sjson[params.state]}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Phone: </span>
                  {params.phone || "(212) 1234567"}
                </Container>

                <Container
                  className={`${css.personalInfoValue} ${css.emailValue}`}
                >
                  <span>Email: </span>
                  {params.email}
                </Container>
              </Container>
            </Container>
          </Container>
          <Container className={css.selectedProductsContainer}>
            <Container>
              <Container className={css.selectedProductsTitle}>
                Selected Products:
              </Container>
            </Container>
            <Container className={css.productCards}>
              {JSON.parse(localStorage.getItem("pivot_plans")).map((p) => {
                return (
                  <Container
                    key={"kky" + p.plan_name.value}
                    className={css.planCard}
                  >
                    <Container className={css.planCardIcon}>
                      <Icon
                        style={{ width: "48px", height: "48px" }}
                        {...getIconPropsForPlan(p)}
                      />
                    </Container>
                    <Container className={css.planCardInfo}>
                      <Container className={css.planCardCarrierName}>
                        {p.carrier.value}
                      </Container>
                      <Container className={css.planCardName}>
                        {p.plan_name.value}
                      </Container>
                    </Container>
                    <Container className={css.planCardPremium}>
                      $
                      {pivotSelectedPlans
                        .filter((p2) => p2.plan_id === p.plan_id.value)[0]
                        .display_info.monthlyCost.toFixed(2)}
                    </Container>
                  </Container>
                );
              })}
              <Container className={css.payments}>
                <Container className={css.applicants}>
                  <Container className={css.applicantLabel}>
                    Applicants:{" "}
                  </Container>
                  <Container className={css.applicantsDropDowns}>
                    {!isChildOnly ? (
                      <>
                        <Container className={css.adultContainer}>
                          {params?.spouse ? "2 Adults" : "1 Adult"}
                        </Container>
                        <Container className={css.dependentContainer}>
                          {props?.applicants?.dependents?.length || 0}{" "}
                          Dependents
                        </Container>
                      </>
                    ) : (
                      <Container
                        className={css.adultContainer}
                        style={{ minWidth: "248px", textAlign: "center" }}
                      >
                        {(props?.applicants?.dependents?.length || 0) + 1}{" "}
                        Children
                      </Container>
                    )}
                  </Container>
                </Container>
                {monthlyFees ? (
                  <Container className={css.tax}>
                    <Container className={css.taxName}>
                      Total monthly fees
                    </Container>
                    <Container className={css.taxValue}>
                      ${monthlyFees.toFixed(2)}{" "}
                      <Tooltip
                        title={<>{monthlyFeesComponent.map((c) => c)}</>}
                        className={css.tooltip}
                      >
                        <Container
                          style={{ height: "16px" }}
                          className={css.tooltipIcon}
                        >
                          <Icon color="#aaa9a9" icon="info" />
                        </Container>
                      </Tooltip>
                    </Container>
                  </Container>
                ) : null}
                {oneTimeFees ? (
                  <Container className={css.tax}>
                    <Container className={css.taxName}>
                      Total one time fees
                    </Container>
                    <Container className={css.taxValue}>
                      ${oneTimeFees.toFixed(2)}{" "}
                      <Tooltip
                        title={<>{oneTimeFeesComponent.map((c) => c)}</>}
                        className={css.tooltip}
                      >
                        <Container
                          style={{ height: "16px" }}
                          className={css.tooltipIcon}
                        >
                          <Icon color="#aaa9a9" icon="info" />
                        </Container>
                      </Tooltip>
                    </Container>
                  </Container>
                ) : null}
                <Container className={css.tax}>
                  <Container className={css.taxName}>Base rate</Container>
                  <Container className={css.taxValue}>
                    ${montlyContribution.toFixed(2)}{" "}
                  </Container>
                </Container>
                <Container className={css.total}>
                  <Container
                    style={{ fontWeight: 700 }}
                    className={css.totalLabel}
                  >
                    Today's Payment
                  </Container>
                  ${(montlyContribution + oneTimeFees + monthlyFees).toFixed(2)}{" "}
                </Container>
                <Container className={css.monthlyTotal}>
                  <Container className={css.monthlyTotalLabel}>
                    Monthly Payment
                  </Container>
                  <Container>
                    ${(montlyContribution + monthlyFees).toFixed(2)}{" "}
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  quote_id,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
  };
};

export default connect(mapStateToProps)(EnrollmentSummary);
