import React from "react";

import { Container } from "../../../..";

import * as css from "./style.module.scss";

import Visa from "../../../../../assets/visa.svg";

import MasterCard from "../../../../../assets/master-card.svg";
import Amex from "../../../../../assets/amex.svg";
import Discover from "../../../../../assets/discover.svg";

export default () => {
  return (
    <Container className={css.creditCardsContainer}>
      <Visa />
      <MasterCard />
      <Discover />
    </Container>
  );
};
