import React, { useState, useEffect } from "react";

import { Container, Icon, TextInput, Select, Checkbox } from "../../..";

import { OfferCard } from "../../../quotes-panel/components";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button, Dropdown, Menu, Radio } from "antd";
import { connect } from "react-redux";

import CreditCards from "./credit-cards";

import axios from "axios";

import moment from "moment";

import { handleInputChange } from "../../../../utils";

import {
  rountingNumberInputHandler,
  ccNumberInputInputHandler,
} from "../../../../utils";

import Toastify from "toastify-js";

const Divider = () => <Container className={css.divider} />;

const BillingAddressIcon = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69 34.5C69 53.5538 53.5538 69 34.5 69C15.4462 69 0 53.5538 0 34.5C0 15.4462 15.4462 0 34.5 0C53.5538 0 69 15.4462 69 34.5Z"
      fill="#002B39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5714 19.25C19.5714 17.8576 20.1133 16.5223 21.0777 15.5377C22.0422 14.5531 23.3503 14 24.7143 14H45.2857C46.6497 14 47.9578 14.5531 48.9223 15.5377C49.8867 16.5223 50.4286 17.8576 50.4286 19.25V50.75C51.1106 50.75 51.7646 51.0266 52.2468 51.5188C52.7291 52.0111 53 52.6788 53 53.375C53 54.0712 52.7291 54.7389 52.2468 55.2312C51.7646 55.7234 51.1106 56 50.4286 56H42.7143C42.0323 56 41.3782 55.7234 40.896 55.2312C40.4138 54.7389 40.1429 54.0712 40.1429 53.375V48.125C40.1429 47.4288 39.8719 46.7611 39.3897 46.2688C38.9075 45.7766 38.2534 45.5 37.5714 45.5H32.4286C31.7466 45.5 31.0925 45.7766 30.6103 46.2688C30.1281 46.7611 29.8571 47.4288 29.8571 48.125V53.375C29.8571 54.0712 29.5862 54.7389 29.104 55.2312C28.6218 55.7234 27.9677 56 27.2857 56H19.5714C18.8894 56 18.2354 55.7234 17.7532 55.2312C17.2709 54.7389 17 54.0712 17 53.375C17 52.6788 17.2709 52.0111 17.7532 51.5188C18.2354 51.0266 18.8894 50.75 19.5714 50.75V19.25ZM27.2857 21.875H32.4286V27.125H27.2857V21.875ZM32.4286 32.375H27.2857V37.625H32.4286V32.375ZM37.5714 21.875H42.7143V27.125H37.5714V21.875ZM42.7143 32.375H37.5714V37.625H42.7143V32.375Z"
      fill="white"
    />
  </svg>
);

const CheckoutRaw = (props) => {
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [exp, setExp] = useState();
  const [cvc, setCvc] = useState("");
  const [cardIssuer, setCardIssuer] = useState("");
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [accountType, setAccountType] = useState();
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState("");
  const [billingAddress, setBillingAddress] = useState(true);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const params = JSON.parse(localStorage.getItem("pivot_params"));
  const spouse =
    Object.keys(props?.spouse).length > 0 ? props?.spouse : undefined;
  const plans = JSON.parse(localStorage.getItem("pivot_plans"));
  const pivotSelectedPlans = JSON.parse(
    localStorage.getItem("pivot-selected-plans")
  ).filter((plan) => plans.some((p) => p.plan_id.value === plan.plan_id));
  const monthly = pivotSelectedPlans.reduce((a, b) => {
    return a + Number(b.display_info.monthlyCost || 0);
  }, 0);
  const monthlyFees = pivotSelectedPlans.reduce(
    (acc, plan) =>
      plan.fees && plan.fees.MONTHLY?.total
        ? acc + plan.fees.MONTHLY.total
        : acc,
    0
  );
  const oneTimeFees = pivotSelectedPlans.reduce(
    (acc, plan) =>
      plan.fees && plan.fees.ONE_TIME?.total
        ? acc + plan.fees.ONE_TIME.total
        : acc,
    0
  );

  const paramsSpouse = params.spouse ? JSON.parse(params.spouse) : undefined;

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1200)).then(() => {
      setLoading(false);
    });
  }, []);

  const isDisabled =
    paymentMethod === "Credit Card"
      ? !firstName ||
        !lastName ||
        !cardNumber ||
        !new RegExp("(0[1-9]|1[0-2])(/)(2[0-9]{3})").test(exp) ||
        cvc?.length <= 2 ||
        !cardIssuer
      : !firstName ||
        !lastName ||
        !accountType ||
        accountNumber?.length < 6 ||
        routingNumber?.length !== 11;

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.checkoutContainer}>
      <Container className={css.paymentInfoIconAndTitle}>
        <Container className={css.paymentInfoIcon}>
          <Icon icon="shopping-cart-circle" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          <Container>Payment Information:</Container>
          <Container className={css.paymentDisclaimer}>
            A payment of ${(monthly + oneTimeFees + monthlyFees).toFixed(2)}{" "}
            will be processed in the submission of your application. It includes
            monthly prices plus any enrollment fees.
          </Container>
        </Container>
      </Container>

      <Container className={css.inputs}>
        <Container>
          <Container className={css.paymentInfosContainer}>
            <Container className={css.paymentInfoContainer}>
              <Container className={css.paymentIcon}>
                <Icon fill="#60cc73" icon="due-today" />
              </Container>
              <Container className={css.paymentRadioButtonTexts}>
                <Container className={css.paymentRadioButtonTitle}>
                  First Payment
                </Container>
                <Container className={css.paymentRadioButtonText}></Container>
              </Container>
              <Container className={css.paymentRadioButtonValue}>
                ${(monthly + oneTimeFees + monthlyFees).toFixed(2)}
              </Container>
            </Container>
            <Container className={css.upcomingPaymentsInfoContainer}>
              <Container className={css.upcomingPaymentsIcon}>
                <Icon fill="#89D8F0" icon="upcoming-payments" />
              </Container>
              <Container className={css.upcomingPaymentsTexts}>
                <Container className={css.upcomingPaymentsTitle}>
                  Monthly Payments
                </Container>
                <Container className={css.upcomingPaymentsText}></Container>
              </Container>
              <Container className={css.upcomingPaymentsValue}>
                ${(monthly + monthlyFees).toFixed(2)}
              </Container>
            </Container>
          </Container>
        </Container>
        <Divider />
        <Container className={css.cardInputs}>
          <Container>
            <Container className={css.paymentMethodContainer}>
              <Container className={css.paymentMethodSelectContainer}>
                <Select
                  value={paymentMethod}
                  size="large"
                  before="Payment Method"
                  onChange={(e) => {
                    setPaymentMethod(e);
                  }}
                  options={[
                    {
                      label: "Bank/ACH Payment",
                      value: "Bank/ACH Payment",
                    },
                    {
                      label: "Credit Card",
                      value: "Credit Card",
                    },
                  ]}
                />
              </Container>
              <Container className={css.creditCards}>
                {paymentMethod == "Credit Card" && <CreditCards />}
                {paymentMethod != "Credit Card" && (
                  <Container
                    className={css.upcomingPaymentsInfoContainer}
                    style={{
                      transform: "translate(-48px, -6px",
                      height: "59px",
                    }}
                  >
                    <Container
                      style={{ marginRight: "24px" }}
                      className={css.n}
                    >
                      <Icon
                        style={{
                          width: "36px",
                          height: "36px",
                          transform: "translateY(2px",
                        }}
                        fill="#77c3ff"
                        icon="info-shield"
                      />
                    </Container>
                    <Container className={css.upcomingPaymentsTexts}>
                      <Container className={css.upcomingPaymentsText}>
                        Your first payment will be securely debited from your
                        bank account with the submission of your application
                      </Container>
                    </Container>
                  </Container>
                )}
              </Container>
            </Container>
          </Container>
          {paymentMethod == "Credit Card" && (
            <>
              <Container className={css.paymentInfoIconAndTitle}>
                <Container className={css.paymentInfoIcon}>
                  <Icon icon="credit-card-circle" />
                </Container>
                <Container className={css.additionalInfoTitle}>
                  <Container>Credit Card info:</Container>
                  <Container className={css.paymentDisclaimer}>
                    Please fill up the next fields exactly as in your credit
                    card
                  </Container>
                </Container>
              </Container>
              <Container>
                <Container className={css.cardNames}>
                  <Container className={`${css.cardName} ${css.firstName}`}>
                    <TextInput
                      onChange={(e) => {
                        setFirstName(handleInputChange(e));
                      }}
                      value={firstName}
                      before="First Name"
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                  <Container className={`${css.cardName} ${css.lastName}`}>
                    <TextInput
                      onChange={(e) => {
                        setLastName(handleInputChange(e));
                      }}
                      value={lastName}
                      before="Last Name"
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                </Container>
              </Container>

              <Container className={css.cardNumbers}>
                <Container className={css.cardNumber}>
                  <TextInput
                    onChange={(e) => {
                      if (e.target.value.length > 19) return e.target.value;

                      const cc = ccNumberInputInputHandler(e);
                      setCardNumber(cc);
                    }}
                    value={cardNumber}
                    before="Card Number"
                  />
                </Container>

                <Container className={css.cardDetails}>
                  <Container className={css.exp}>
                    <TextInput
                      onChange={(e) => {
                        if (e.target.value.length > 7) return;

                        const divider =
                          e.target.value.length == 2 &&
                          e.target.value.length > exp?.length
                            ? "/"
                            : "";

                        setExp(handleInputChange(e) + divider);
                      }}
                      value={exp}
                      style={{ paddingLeft: "10px" }}
                      mask={new RegExp("(0[1-9]|1[0-2])(/)(2[0-9]{3})")}
                      placeholder="Expiration Date"
                      beforeWidth="60px"
                      focusedlabel={"MM/YYYY"}
                      beforeClassName={css.inputsBefore}
                    />
                  </Container>
                  <Container className={css.cvc}>
                    <TextInput
                      value={cvc}
                      onChange={(e) => {
                        if (e.target.value > 9999) return;
                        setCvc(handleInputChange(e));
                      }}
                      mask={new RegExp("([0-9]{3}[0-9]?)")}
                      type="number"
                      placeholder="CVC"
                    />
                  </Container>
                </Container>
              </Container>
              <Container className={css.radioGroupContainer}>
                <Radio.Group
                  value={cardIssuer}
                  onChange={(v) => {
                    setCardIssuer(v.target.value);
                  }}
                  buttonStyle="solid"
                  size="large"
                >
                  <Radio value="DISCOVER">Discover</Radio>
                  <Radio value="MASTERCARD">Mastercard</Radio>
                  <Radio value="VISA">Visa</Radio>
                </Radio.Group>
              </Container>
              <Container className={css.billingAddressContainer}>
                <Checkbox
                  id="checkout-form-billing-address-checkbox"
                  checked={billingAddress}
                  onChange={(e) => {
                    setBillingAddress(e.target.checked);
                  }}
                />
                <p>Billing address should be the same as the address.</p>
              </Container>
              {!billingAddress && (
                <Container className={css.lastInputs}>
                  <Container className={css.householdContainer}>
                    <Container className={css.paymentInfoIconAndTitle}>
                      <Container className={css.paymentInfoIcon}>
                        <BillingAddressIcon />
                      </Container>
                      <Container className={css.additionalInfoTitle}>
                        <Container>Billing Address:</Container>
                        <Container className={css.paymentDisclaimer}>
                          Please insert your billing address:
                        </Container>
                      </Container>
                    </Container>
                    <Container className={css.addressInputs}>
                      <TextInput
                        name="test-enrollment-enrollment-additionalinfo-input-street-adress"
                        value={address}
                        onChange={(e) => {
                          setAddress(handleInputChange(e));
                        }}
                        placeholder="Street Address"
                        focusedlabel="Street Address"
                      />
                      <TextInput
                        name="test-enrollment-enrollment-additionalinfo-input-city"
                        value={city}
                        onChange={(e) => {
                          setCity(handleInputChange(e));
                        }}
                        placeholder="City"
                        focusedlabel="City"
                      />
                    </Container>
                  </Container>
                </Container>
              )}
            </>
          )}
          {paymentMethod != "Credit Card" && (
            <>
              <Container className={css.paymentInfoIconAndTitle}>
                <Container className={css.paymentInfoIcon}>
                  <Icon icon="bank-acc" />
                </Container>
                <Container className={css.additionalInfoTitle}>
                  <Container>Banking account info:</Container>
                  <Container className={css.paymentDisclaimer}>
                    Fields should be in accord to the banking account that will
                    be used for your payments.
                  </Container>
                </Container>
              </Container>
              <Container>
                <Container className={css.cardNames}>
                  <Container className={`${css.cardName} ${css.firstName} `}>
                    <TextInput
                      onChange={(e) => {
                        setFirstName(handleInputChange(e));
                      }}
                      value={firstName}
                      before="First Name"
                    />
                  </Container>
                  <Container className={`${css.cardName} ${css.lastName}`}>
                    <TextInput
                      onChange={(e) => {
                        setLastName(handleInputChange(e));
                      }}
                      value={lastName}
                      before="Last Name"
                    />
                  </Container>
                </Container>
              </Container>

              <Container className={css.cardNames}>
                <Container className={`${css.cardName} ${css.firstName} `}>
                  <Select
                    value={accountType}
                    size="large"
                    before="Account type"
                    onChange={(e) => {
                      setAccountType(e);
                    }}
                    options={[
                      {
                        label: "Checking",
                        value: "Checking",
                      },
                      {
                        label: "Savings",
                        value: "Savings",
                      },
                    ]}
                  />
                </Container>
                <Container className={`${css.cardName} ${css.lastName} `}>
                  <TextInput
                    onChange={(e) => {
                      setBankName(handleInputChange(e));
                    }}
                    value={bankName}
                    before="Bank Name"
                  />
                </Container>
              </Container>

              <Container className={css.cardNames}>
                <Container className={`${css.cardName} ${css.firstName} `}>
                  <TextInput
                    onChange={(e) => {
                      setAccountNumber(handleInputChange(e));
                    }}
                    value={accountNumber}
                    before="Account Number"
                    mask={new RegExp("[0-9]{6,16}")}
                  />
                </Container>
                <Container className={`${css.cardName} ${css.lastName}`}>
                  <TextInput
                    value={routingNumber}
                    onChange={(e) =>
                      setRoutingNumber(rountingNumberInputHandler(e))
                    }
                    before="Routing Number"
                    mask={new RegExp("[0-9]{3} [0-9]{3} [0-9]{3}")}
                  />
                </Container>
              </Container>
            </>
          )}
        </Container>
      </Container>
      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            onClick={async () => {
              setLoading(true);
              try {
                const resp = await axios({
                  method: "post",
                  url: `${props.baseUrl}/v1/enrollment/pivot`,
                  data: {
                    ...{
                      quote_id: params.quote_id,
                      quote_key: window.localStorage.getItem("pivot-quote-key"),
                      compliance_token: props.compliance_token,
                      primary_applicant: {
                        first_name: params.firstName,
                        last_name: params.lastName,
                        date_of_birth: props.date_of_birth
                          .toDate()
                          .toISOString()
                          .split("T")[0],
                        gender: params.gender,
                        email: params.email,
                        phone: params.phone,
                        sms_allowed: params.smsAllowed,
                      },
                      spouse: spouse
                        ? {
                            first_name: paramsSpouse.firstName,
                            last_name: paramsSpouse.lastName,
                            date_of_birth:
                              typeof props.spouse.date_of_birth === "string"
                                ? props.spouse.date_of_birth
                                : props.spouse.date_of_birth
                                    .toDate()
                                    .toISOString()
                                    .split("T")[0],
                            gender: paramsSpouse.gender,
                          }
                        : undefined,
                      dependents: props.dependents
                        ? props.dependents.map((d, ind) => {
                            return {
                              first_name: d.first_name,
                              last_name: d.last_name,
                              date_of_birth: d.date_of_birth
                                .toDate()
                                .toISOString()
                                .split("T")[0],
                              gender: d.gender,
                            };
                          })
                        : [],
                      residential_address: {
                        city: props.city,
                        state: params.state,
                        street: props.address,
                        county: params.county,
                        zip_code: params.zip_code,
                      },
                      billing_address: {
                        city: !billingAddress ? city : props.city,
                        state: params.state,
                        street: !billingAddress
                          ? `${address}`
                          : `${props.address}`,
                        county: params.county,
                        zip_code: params.zip_code,
                      },
                      selected_plans: pivotSelectedPlans.map(
                        (plan) => plan.info
                      ),
                      payment:
                        paymentMethod != "Credit Card"
                          ? {
                              first_name: firstName,
                              last_name: lastName,
                              account_type: accountType || "Checking",
                              routing_number: routingNumber, //044000037
                              account_number: accountNumber, // 1234567890
                              bank_name: bankName, // Chase Bank
                            }
                          : {
                              first_name: firstName,
                              last_name: lastName,
                              credit_card_number: cardNumber,
                              expiration_date: exp,
                              cvv: cvc + "",
                              card_issuer: cardIssuer,
                            },
                    },
                  },
                  headers: {
                    "Apollo-Quotes-Source": props.agentMetadata.id,
                  },
                });
                setLoading(false);

                if (Number(resp.status) == 200) {
                  props.setAdditionalInfo({
                    key: "userEmail",
                    value: params.email,
                  });
                  props.setAdditionalInfo({
                    key: "linkToEnrollment",
                    value: resp.data.memberPortalUrl,
                  });

                  props.setCurrentStep(3);
                } else {
                  const errorMessage = resp?.data?.detail?.description
                    ? resp?.data?.detail?.description
                    : resp?.data?.detail
                    ? resp?.data?.detail
                    : "We are sorry, we couldn't complete your application, please try again in a few minutes.";
                  Toastify({
                    text: errorMessage,
                    duration: 10000,
                    newWindow: true,
                    close: true,
                    gravity: "bottom",
                    position: "center",
                    backgroundColor: "#D33F49",
                    stopOnFocus: true,
                    className: "notification",
                  }).showToast();
                }
              } catch (ex: any) {
                console.log(ex);
                setLoading(false);
                const errorMessage = ex?.response?.data?.detail?.description
                  ? ex?.response?.data?.detail?.description
                  : ex?.response?.data?.detail
                  ? ex?.response?.data?.detail
                  : "We are sorry, we couldn't complete your application, please try again in a few minutes.";
                Toastify({
                  text:
                    ex?.response?.data?.detail?.data?.description ||
                    errorMessage,
                  duration: 10000,
                  newWindow: true,
                  close: true,
                  gravity: "bottom",
                  position: "center",
                  backgroundColor: "#D33F49",
                  stopOnFocus: true,
                  className: "notification",
                }).showToast();
              }
            }}
            type="primary"
            shape="round"
            disabled={isDisabled}
          >
            Confirm Payment
          </Button>
        </Container>
      </Container>
      <Container className={css.disclaimerContainer}>
        The accountholder of the bank account of credit card provider during
        this enrollment process and requests the insurer to initiate automatic
        electronic payments against such indicated bank <br /> account of credit
        card for the payment of premiuns and other indicated monthly dues
        included in the plan(s) being purchased during this enrollment process.
        Accountholder agrees that the <br />
        electronic payment authorization for such automatic payments may be
        terminated by providing written notice to the insurer.
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  quote_id,
  enrollment,
  applicants,
  specialRates,
}) => {
  return {
    applicants,
    baseUrl,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
    specialRates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
    confirmApplication: (payload) =>
      dispatch({ type: `SET_APPLICATION_URL`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutRaw);
