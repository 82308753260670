// extracted by mini-css-extract-plugin
export var confirmApplicationButton = "style-module--confirm-application-button--3eGI3";
export var confirmApplicationText = "style-module--confirm-application-text--5PSLl";
export var congratulationsText = "style-module--congratulations-text--CBeXC";
export var contentContainer = "style-module--content-container--9Zgez";
export var emailSentText = "style-module--email-sent-text--cEiDr";
export var fadeIn = "style-module--fade-in--tJqAD";
export var header = "style-module--header--AqPir";
export var logoContainer = "style-module--logo-container--MpSTt";
export var lottieContainer = "style-module--lottie-container--7FS2m";
export var mainContainer = "style-module--main-container--UmpYa";
export var step = "style-module--step--vT0ae";
export var stepsContainer = "style-module--steps-container--RMl84";
export var timer = "style-module--timer--NoZRA";
export var transition = "style-module--transition--cwY6+";
export var transitiona = "style-module--transitiona--iz4tf";
export var transitionb = "style-module--transitionb--iMDko";