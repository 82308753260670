import React, { useState, useEffect, isValidElement } from "react";

import {
  Container,
  ConversionSlider,
  DatePicker,
  DoubledInputSlider,
  Icon,
  InputSlider,
  TextInput,
  Select,
} from "../../..";

import { OfferCard } from "../../../quotes-panel/components";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button } from "antd";
import { connect } from "react-redux";

import moment from "moment";
import axios from "axios";
import { getIconPropsForPlan, handleInputChange } from "../../../../utils";
import { formatCurrency } from "../../../../utils";

import _ from "lodash";

const AdditionalInfoRaw = (props) => {
  const [loading, setLoading] = useState(true);

  const specialRates = props.specialRates || {};

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
      setLoading(false);
    });
  }, []);
  const params = JSON.parse(localStorage.getItem("pivot_params"));

  React.useEffect(() => {
    const plans = JSON.parse(localStorage.getItem("pivot-selected-plans"));
    if (!plans) return;

    plans.forEach((plan) => {
      plan.display_info.consecutive === 2147483647
        ? props.setAdditionalInfo({
            key: `consecutive-policies-${plan.plan_id}`,
            value: 2147483647,
          })
        : props.setAdditionalInfo({
            key: `consecutive-policies-${plan.plan_id}`,
            value: plan.display_info.consecutive,
          });
    });
  }, []);

  useEffect(() => {
    if (!params) return;

    if (params?.spouse) {
      props.setAdditionalInfo({
        key: "spouse",
        value: JSON.parse(params?.spouse),
      });
    }
  }, []);

  useEffect(() => {
    if (!params) return;

    if (
      JSON.stringify(params?.date_of_birth) !==
      JSON.stringify(props.date_of_birth)
    ) {
      props.setAdditionalInfo({
        key: "date_of_birth",
        value: moment(params.date_of_birth),
      });
    }

    if (
      props.applicants?.dependents &&
      props.applicants?.dependents.length > 0
    ) {
      const newDependents = props.applicants.dependents.map((d) => {
        return {
          ...d,
          date_of_birth: d.date_of_birth ? moment(d.date_of_birth) : undefined,
        };
      });

      props.setApplicants({
        dependents: newDependents,
      });
    }
  }, []);

  params.dependents = params?.dependents ? JSON.parse(params.dependents) : [];
  const spouse =
    Object.keys(props?.spouse).length > 0 ? props?.spouse : undefined;
  const dependents = props.applicants.dependents;

  const isValid = () => {
    let spouseValid = true;

    if (spouse) {
      if (!props.spouse.date_of_birth) spouseValid = false;
    }

    let dependentsValid = true;

    props.dependents.forEach((d) => {
      if (!d.first_name || !d.last_name || !d.date_of_birth) {
        dependentsValid = false;
      }
    });

    const isCoverageSelected = JSON.parse(
      localStorage.getItem("pivot_plans")
    ).every((plan) =>
      props.enrollment.nationalGeneral.additionalInfo[
        `consecutive-policies-${plan.plan_id.value}`
      ]
        ? true
        : false
    );

    return (
      !spouseValid ||
      !dependentsValid ||
      !props.address ||
      !props.city ||
      !props.date_of_birth ||
      !isCoverageSelected
    );
  };

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.additionalInfoContainer}>
      <Container className={css.additionalInfoIconAndTitle}>
        <Container className={css.additionalInfoIcon}>
          <Icon icon="additional-info" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          Enter your personal info:
        </Container>
      </Container>
      <Container className={css.inputs}>
        <Container className={css.applicantContainer}>
          <Container className={css.applicantTitle}>
            <Icon
              fill="#89D8F0"
              className={css.applicantIcon}
              icon="user-circle"
            />{" "}
            Main Applicant:
          </Container>
          <Container>
            <Container>
              <Container className={css.birthdateContainer}>
                <DatePicker
                  disabled
                  name="test-enrollment-enrollment-tools-additionalinfo-input-datebirth"
                  value={
                    typeof props.date_of_birth === "object"
                      ? props.date_of_birth
                      : typeof props.date_of_birth === "string"
                      ? moment(props.date_of_birth)
                      : null
                  }
                  onChange={(e) => {
                    props.setAdditionalInfo({ key: "date_of_birth", value: e });
                  }}
                  before={"Date Of Birth"}
                  focusedlabel="Date of Birth"
                />
              </Container>
            </Container>
          </Container>
        </Container>

        {spouse && (
          <Container className={css.applicantContainer}>
            <Container className={css.applicantTitle}>
              <Icon
                fill="#89D8F0"
                className={css.applicantIcon}
                icon="spouse-circle"
              />{" "}
              Spouse:
            </Container>
            <Container>
              <Container>
                {/* <Container className={css.firstNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          first_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.first_name}
                    focusedlabel="Fist Name"
                    placeholder="First Name"
                  />
                </Container>
                <Container className={css.lastNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          last_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.last_name}
                    placeholder="Last Name"
                    focusedlabel="Last Name"
                  />
                </Container> */}
                <Container className={css.birthdateContainer}>
                  <DatePicker
                    disabled
                    value={
                      typeof props.spouse.date_of_birth === "object"
                        ? props.spouse.date_of_birth
                        : typeof props.spouse.date_of_birth === "string"
                        ? moment(props.spouse.date_of_birth)
                        : null
                    }
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: { ...props.spouse, date_of_birth: e },
                      });
                    }}
                    before={"Date Of Birth"}
                    focusedlabel="Date of Birth"
                  />
                </Container>
              </Container>
            </Container>
          </Container>
        )}
        {dependents.map((dep, ind) => {
          return (
            <Container className={css.applicantContainer}>
              <Container className={css.applicantTitle}>
                <Icon
                  fill="#89D8F0"
                  className={css.applicantIcon}
                  icon="dependent-circle"
                />{" "}
                Dependent {ind + 1}:
                <Container className={css.dependentInfoContainer}>
                  <span>
                    Age <strong>{dep.age}</strong>
                  </span>
                  <span>
                    Gender{" "}
                    <Icon
                      icon={`gender-${dep.gender.toLowerCase()}`}
                      className={css.dependentGender}
                    />
                  </span>
                </Container>
              </Container>
              <Container>
                <Container>
                  <Container className={css.firstNameInputContainer}>
                    <TextInput
                      value={dependents[ind].first_name}
                      placeholder="First Name"
                      focusedlabel="First Name"
                      onChange={(e) => {
                        dependents[ind].first_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.lastNameInputContainer}>
                    <TextInput
                      value={dependents[ind].last_name}
                      placeholder="Last Name"
                      focusedlabel="Last Name"
                      onChange={(e) => {
                        dependents[ind].last_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.birthdateContainer}>
                    <DatePicker
                      disabled
                      value={
                        typeof dependents[ind].date_of_birth === "object"
                          ? dependents[ind].date_of_birth
                          : typeof dependents[ind].date_of_birth === "string"
                          ? moment(dependents[ind].date_of_birth)
                          : null
                      }
                      before={"Date Of Birth"}
                      onChange={(e) => {
                        dependents[ind].date_of_birth = e;

                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                      focusedlabel="Date of Birth"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>
      <Container className={css.lastInputs}>
        <Container className={css.householdContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon fill="#004C64" icon="building" />
            </Container>
            <Container>
              <Container
                style={{ marginLeft: "2px" }}
                className={css.householdTitle}
              >
                Complete Household Address:
              </Container>
            </Container>
          </Container>
          <Container className={css.addressInputs}>
            <Container className={css.streetAndNumberContainer}>
              <Container className={css.addressStreetNameInput}>
                <TextInput
                  name="test-enrollment-enrollment-additionalinfo-input-street-adress"
                  value={props.address}
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "address",
                      value: handleInputChange(e),
                    });
                  }}
                  placeholder="Street Address"
                  focusedlabel="Street Address"
                />
              </Container>
              <Container className={css.addressNumberInput}>
                <TextInput
                  name="test-enrollment-enrollment-additionalinfo-input-apt-unit"
                  placeholder="APT.#, Unit"
                  value={props.apt}
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "apt",
                      value: handleInputChange(e),
                    });
                  }}
                />
              </Container>
            </Container>
          </Container>
        </Container>
        <Container className={css.cityContainer}>
          <Container className={css.addressCityInput}>
            <TextInput
              name="test-enrollment-enrollment-additionalinfo-input-city"
              value={props.city}
              onChange={(e) => {
                props.setAdditionalInfo({
                  key: "city",
                  value: handleInputChange(e),
                });
              }}
              placeholder="City"
              focusedlabel="City"
            />
          </Container>
        </Container>
      </Container>
      {/* <Container className={css.lastInputs}>
        <Container className={css.coverageContainer}>
          <Container
            className={`${css.householdIconAndTitle}`}
            style={{ marginLeft: "0px" }}
          >
            <Container className={`${css.householdIcon}`}>
              <Icon fill="#004C64" icon="coverage" />
            </Container>
            <Container>
              <Container
                style={{ marginLeft: "2px" }}
                className={css.householdTitle}
              >
                Coverage Period:
              </Container>
            </Container>
          </Container>
          <Container className={css.coverageInputsContainer}>
            {JSON.parse(localStorage.getItem("pivot_plans")).map((p) => {
              if (
                p.general_info.term_information.max_consecutive === 2147483647
              ) {
                return (
                  <Container className={css.selectContainer}>
                    <Container className={css.selectTitle}>
                      Coverage period for {p.plan_name.value}: Until
                      cancellation
                    </Container>
                  </Container>
                );
              }

              return (
                <Container className={css.selectContainer}>
                  <Container className={css.selectTitle}>
                    Coverage period for {p.plan_name.value}
                  </Container>
                  <Select
                    size="large"
                    before="Select the duration of coverage"
                    onChange={(value) => {
                      props.setAdditionalInfo({
                        key: `consecutive-policies-${p.plan_id.value}`,
                        value,
                      });
                    }}
                    value={props[`consecutive-policies-${p.plan_id.value}`]}
                    options={[
                      ...Array.from(
                        {
                          length:
                            p.general_info.term_information.max_consecutive <=
                            12
                              ? p.general_info.term_information.max_consecutive
                              : 12,
                        },
                        (v, k) => k + 1
                      ).map((v) => ({
                        value: v,
                        label: v,
                      })),
                      ...(p.general_info.term_information.max_consecutive > 12
                        ? [{ value: 2147483647, label: "Until cancellation" }]
                        : []),
                    ]}
                  />
                </Container>
              );
            })}
          </Container>
        </Container>
        <Container className={css.coverageContainer}>
          <Container className={css.plansContainer}>
            {JSON.parse(localStorage.getItem("pivot_plans")).map((p) => {
              return (
                <Container className={css.planCard}>
                  <Container className={css.planCardHeader}>
                    <Container>
                      <Icon
                        style={{ width: "48px", height: "48px" }}
                        {...getIconPropsForPlan(p)}
                      />
                    </Container>
                    <Container className={css.textContainer}>
                      <Container className={css.textSpaceBetween}>
                        <strong>Pivot Health Plan</strong>
                        <span>
                          ${Number(p.plan_only_premium.value).toFixed(2)}
                        </span>
                      </Container>
                      <small>{p.plan_name.value}</small>
                    </Container>
                  </Container>
                  <Container className={css.infoContainer}>
                    {props.enrollment.nationalGeneral.additionalInfo[
                      `consecutive-policies-${p.plan_id.value}`
                    ] !== 2147483647 ? (
                      <>
                        <Container className={css.textSpaceBetween}>
                          <p>Monthly Cost</p>
                          <span>
                            ${Number(p.plan_only_premium.value).toFixed(2)}
                          </span>
                        </Container>
                        <Container className={css.textSpaceBetween}>
                          <p>Plan Duration</p>
                          <span>
                            {p.general_info.term_information.duration *
                              (props.enrollment.nationalGeneral.additionalInfo[
                                `consecutive-policies-${p.plan_id.value}`
                              ] || 1)}{" "}
                            days
                          </span>
                        </Container>
                        <Container className={css.textSpaceBetween}>
                          <p>Consecutive</p>
                          <span>
                            {props.enrollment.nationalGeneral.additionalInfo[
                              `consecutive-policies-${p.plan_id.value}`
                            ] || "--"}
                          </span>
                        </Container>
                      </>
                    ) : (
                      <Container className={css.textSpaceBetween}>
                        <p>Plan Duration</p>
                        <span>Until cancellation</span>
                      </Container>
                    )}
                  </Container>
                </Container>
              );
            })}
          </Container>
        </Container>
      </Container> */}

      <Container className={css.priceDisclaimer}>
        *The monthly payment of $
        {JSON.parse(localStorage.getItem("pivot_plans")).reduce((a, b) => {
          return a + b.premium.value;
        }, 0)}{" "}
        is consistent with the selected date and term length. <br />
        By setting another date the value will be changed automatically
      </Container>
      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            onClick={() => {
              props.previous && props.previous();
            }}
            type="primary"
            shape="round"
          >
            Previous
          </Button>
        </Container>
        <Container className={css.nextButton}>
          <Button
            disabled={isValid()}
            onClick={() => {
              props.next && props.next();
            }}
            type="primary"
            shape="round"
          >
            Next
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  enrollment,
  applicants,
  specialRates,
  quote_id,
  ...rest
}) => {
  return {
    applicants,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
    ...rest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartValues: (payload) => {
      dispatch({ type: `UPDATE_CART_VALUES`, payload });
    },
    updateSpecialRates: (payload) =>
      dispatch({ type: `UPDATE_SPECIAL_RATES`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
    setApplicants: (payload) => dispatch({ type: `SET_APPLICANTS`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRaw);
