import React, { useEffect, useState } from "react";
import { Container } from "../../..";
import { v4 as uuidv4 } from "uuid";
import * as css from "./style.module.scss";
import { Button, Radio, Input, Space, Checkbox } from "antd";
import { connect } from "react-redux";
import Toastify from "toastify-js";
import axios from "axios";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";
import { capitalizeFirstLetter } from "../../../../utils";

const EligibiltyQuestionsRaw = (props) => {
  const [questions, setQuestions] = useState<any>([]);
  const params = JSON.parse(localStorage.getItem("pivot_params"));
  const quoteKey = localStorage.getItem("pivot-quote-key");
  const [answers, setAnswers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isEligibilityToastOpen, setIsEligibilityToastOpen] =
    React.useState(false);
  const [guardianSignature, setGuardianSignature] = useState("");
  const [hasAgreedWithTerms, setHasAgreedWithTerms] = useState(false);
  const [hasAgreedWithAgreements, setHasAgreedWithAgreements] = useState(false);
  const [hasAgreedWithDisclousures, setHasAgreedWithDisclousures] =
    useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [isSpouseSigned, setIsSpouseSigned] = useState(false);
  const [isGuardianSigned, setIsGuardianSigned] = useState(false);
  const isSpouseSignatureRequired =
    params.spouse &&
    questions.required_signatures?.some((d) => d.applicant === "SPOUSE");
  console.log(params.spouse);
  const isPrimaryApplicantSignatureRequired =
    questions.required_signatures?.some((d) => d.applicant === "PRIMARY");
  const isGuardianSignatureRequired = questions.required_signatures?.some(
    (d) => d.applicant === "GUARDIAN"
  );
  const spouse = params.spouse ? JSON.parse(params.spouse) : undefined;
  const hasNotExpectedAnswers = answers.some((a) => {
    const question = questions.questions.find(
      (q) => q.question_id === a.question_id
    );
    return (
      question?.expected_answer_id?.includes(Number(a.answer_id)) === false
    );
  });

  const answerQuestion = (questionId, answerId) => {
    const answer = {
      question_id: questionId,
      answer_id: answerId,
    };
    const newAnswers = answers.filter((a) => a.question_id !== questionId);
    newAnswers.push(answer);
    setAnswers(newAnswers);
  };

  const shouldDisplayDisclosures =
    questions?.accepted_disclosures?.length > 0
      ? questions.accepted_disclosures.some((d) => {
          if (d.conditional_visibility?.length === 0) {
            return true;
          } else {
            return d.conditional_visibility.some((c) =>
              answers.some((a) => a.answer_id == c.answer)
            );
          }
        })
      : false;

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.post(
          `${props.baseUrl}/v1/enrollment/pivot/eligibility-questions/${params.quote_id}`,
          {
            agent_id: params.brokerId,
            state: params.state,
            compliance_product_type: JSON.parse(params.pivot_plans).map(
              (p) => ({
                name: p.general_info.compliance_product_type
                  .toUpperCase()
                  .replace(" ", "_"),
              })
            ),
            quote_key: quoteKey,
          }
        );
        setQuestions(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  }, []);

  const handleAnswerQuestion = async () => {
    const primarySignature = isPrimaryApplicantSignatureRequired
      ? {
          id: 1,
          name: `${params.firstName} ${params.lastName}`,
        }
      : undefined;
    const spouseSignature = isSpouseSignatureRequired
      ? {
          id: 2,
          name: `${spouse.firstName} ${spouse.lastName}`,
        }
      : undefined;
    const guardianSignaturee = isGuardianSignatureRequired
      ? {
          id: 4,
          name: guardianSignature,
        }
      : undefined;

    const { data } = await axios.post(
      `${props.baseUrl}/v1/enrollment/pivot/eligibility-questions/validate/${params.quote_id}`,
      {
        impression_id: questions.impression_id,
        answers,
        agreed_terms: questions.agreed_terms.map((t) => t.id),
        accepted_agreements: questions.accepted_agreements.map((t) => t.id),
        accepted_disclosures: questions.accepted_disclosures.map((t) => t.id),
        required_signatures: [
          ...(primarySignature ? [primarySignature] : []),
          ...(spouseSignature ? [spouseSignature] : []),
          ...(guardianSignaturee ? [guardianSignaturee] : []),
        ],
      }
    );
    props.setAdditionalInfo({
      key: "compliance_token",
      value: data.compliance_token,
    });
  };

  React.useEffect(() => {
    if (hasNotExpectedAnswers) {
      if (isEligibilityToastOpen) return;
      Toastify({
        text: "Based on your answer we are unable to issue coverage.",
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
        callback: () => setIsEligibilityToastOpen(false),
      }).showToast();
      setIsEligibilityToastOpen(true);
    }
  }, [hasNotExpectedAnswers]);

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container>
      <Container className={css.disclaimerContainer}>
        <h1 className={css.eligibilityQuestionsTitle}>Eligibility Questions</h1>
      </Container>
      {questions?.questions &&
        questions.questions.map((q, index) => {
          return (
            <Container className={css.eligibilityQuestion} key={q.question_id}>
              <Container style={{ display: "flex" }}>
                <Container className={css.eligibilityQuestionIndex}>
                  {index + 1}.
                </Container>
                <Container
                  className={`${css.eligibilityQuestionText} ${
                    answers.some((a) => a.question_id == q.question_id)
                      ? answers.some((a) =>
                          q.expected_answer_id.includes(Number(a.answer_id))
                        )
                        ? css.eligibilityQuestionOk
                        : css.eligibilityQuestionError
                      : ""
                  } `}
                >
                  <div dangerouslySetInnerHTML={{ __html: q.question }} />{" "}
                </Container>
              </Container>
              <Container
                className={`${css.eligibilityQuestionRadio} hidden-on-mobile`}
              >
                <Radio.Group
                  buttonStyle="solid"
                  size="large"
                  onChange={(e) =>
                    answerQuestion(q.question_id, e.target.value)
                  }
                >
                  <Space direction="vertical">
                    {q.answers.map((a) => (
                      <Radio value={a.answer_id}>
                        {capitalizeFirstLetter(a.answer)}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Container>
              <Container
                className={`${css.eligibilityQuestionRadio} hidden-on-web`}
              >
                <Radio.Group
                  buttonStyle="solid"
                  size="large"
                  onChange={(e) =>
                    answerQuestion(q.question_id, e.target.value)
                  }
                >
                  <Space direction="horizontal">
                    {q.answers.map((a) => (
                      <Radio className={css.radio} value={a.answer_id}>
                        {capitalizeFirstLetter(a.answer)}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Container>
            </Container>
          );
        })}
      <Container className={css.aboutContainer}>
        {questions?.disclaimers && (
          <Container className={css.disclaimerContainer}>
            <h1 className={css.eligibilityQuestionsTitle}>Disclaimer</h1>
            {questions.disclaimers.map((d, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: d.details }} />
            ))}
          </Container>
        )}
        {questions?.accepted_agreements && (
          <Container className={css.disclaimerContainer}>
            <h1 className={css.eligibilityQuestionsTitle}>Agreements</h1>
            {questions.accepted_agreements.map((d, i) => (
              <div>
                <div key={i} dangerouslySetInnerHTML={{ __html: d.details }} />
              </div>
            ))}
            <span>
              <Checkbox
                checked={hasAgreedWithAgreements}
                onChange={(e) => setHasAgreedWithAgreements(e.target.checked)}
              />{" "}
              I agree with all the information described above{" "}
            </span>
          </Container>
        )}
        {questions?.agreed_terms && (
          <Container className={css.disclaimerContainer}>
            <h1 className={css.eligibilityQuestionsTitle}>
              Terms and Conditions
            </h1>
            {questions.agreed_terms.map((d, i) => (
              <div>
                <div key={i} dangerouslySetInnerHTML={{ __html: d.details }} />
              </div>
            ))}
            <span>
              <Checkbox
                checked={hasAgreedWithTerms}
                onChange={(e) => setHasAgreedWithTerms(e.target.checked)}
              />{" "}
              I agree with all the information described above{" "}
            </span>
          </Container>
        )}
        {questions?.accepted_disclosures && shouldDisplayDisclosures && (
          <Container className={css.disclaimerContainer}>
            <h1 className={css.eligibilityQuestionsTitle}>Disclosures</h1>
            {questions.accepted_disclosures.map((d, i) => {
              if (d.conditional_visibility.length > 0) {
                const conditionalVisibility = d.conditional_visibility.some(
                  (c) => answers.some((a) => a.answer_id == c.answer)
                );
                if (conditionalVisibility) {
                  return (
                    <>
                      <div
                        key={i}
                        dangerouslySetInnerHTML={{ __html: d.details }}
                      />
                    </>
                  );
                } else {
                  return null;
                }
              } else {
                return (
                  <div
                    key={i}
                    dangerouslySetInnerHTML={{ __html: d.details }}
                  />
                );
              }
            })}
            <span>
              <Checkbox
                checked={hasAgreedWithDisclousures}
                onChange={(e) => setHasAgreedWithDisclousures(e.target.checked)}
              />{" "}
              I agree with all the information described above{" "}
            </span>
          </Container>
        )}
        {(isPrimaryApplicantSignatureRequired ||
          isSpouseSignatureRequired ||
          isGuardianSignatureRequired) && (
          <Container className={css.disclaimerContainer}>
            <h1 className={css.eligibilityQuestionsTitle}>
              Eletronic signature
            </h1>
            {isPrimaryApplicantSignatureRequired && (
              <div>
                <div className={css.signatureGrid}>
                  <p>Primary applicant signature</p>
                  <p>Date signed</p>
                  <div>
                    <div
                      className={css.signatureInput}
                      data-disabled={isSigned}
                    >
                      {isSigned ? (
                        <p>
                          {params.firstName} {params.lastName}
                        </p>
                      ) : (
                        <div
                          className={css.clickSign}
                          onClick={() => setIsSigned(true)}
                        >
                          Click to sign
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={css.signatureInput} data-disabled="true">
                      <p>
                        {new Date().toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isSpouseSignatureRequired && (
              <div>
                <div className={css.signatureGrid}>
                  <p>Spouse signature</p>
                  <p>Date signed</p>
                  <div>
                    <div
                      className={css.signatureInput}
                      data-disabled={isSigned}
                    >
                      {isSpouseSigned ? (
                        <p>
                          {spouse.firstName} {spouse.lastName}
                        </p>
                      ) : (
                        <div
                          className={css.clickSign}
                          onClick={() => setIsSpouseSigned(true)}
                        >
                          Click to sign
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={css.signatureInput} data-disabled="true">
                      <p>
                        {new Date().toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isGuardianSignatureRequired && (
              <div>
                <div className={css.signatureGrid}>
                  <p>Guardian signature</p>
                  <p>Date signed</p>
                  {isGuardianSigned ? (
                    <div
                      className={css.signatureInput}
                      data-disabled={isGuardianSigned}
                    >
                      <p>{guardianSignature}</p>
                    </div>
                  ) : (
                    <div className={css.writableSign}>
                      <input
                        type="text"
                        value={guardianSignature}
                        onChange={(e) =>
                          setGuardianSignature(e.currentTarget.value)
                        }
                      />
                      <button
                        disabled={guardianSignature.length < 1}
                        type="button"
                        onClick={() => {
                          setIsGuardianSigned(true);
                          props.setAdditionalInfo({
                            key: "guardianSignature",
                            value: guardianSignature,
                          });
                        }}
                      >
                        Sign
                      </button>
                    </div>
                  )}
                  <div>
                    <div className={css.signatureInput} data-disabled="true">
                      <p>
                        {new Date().toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Container>
        )}
      </Container>

      <Container className={css.nextButton}>
        <Button
          onClick={async () => {
            try {
              await handleAnswerQuestion();
              props.next && props.next();
            } catch (e) {
              if (isEligibilityToastOpen) return;
              Toastify({
                text: "Based on your answer we are unable to issue coverage.",
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "bottom",
                position: "center",
                backgroundColor: "#D33F49",
                stopOnFocus: true,
                className: "notification",
                callback: () => setIsEligibilityToastOpen(false),
              }).showToast();
              setIsEligibilityToastOpen(true);
            }
          }}
          disabled={
            answers.length !== questions?.questions?.length ||
            !hasAgreedWithAgreements ||
            !hasAgreedWithTerms ||
            hasNotExpectedAnswers ||
            (shouldDisplayDisclosures && !hasAgreedWithDisclousures) ||
            (isPrimaryApplicantSignatureRequired && !isSigned) ||
            (isSpouseSignatureRequired && !isSpouseSigned) ||
            (isGuardianSignatureRequired && !isGuardianSigned)
          }
          type="primary"
          shape="round"
          name="test-enrollment-enrollment-tools-eligibility-button-next"
        >
          Next
        </Button>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({ baseUrl, setAdditionalInfo }) => {
  return {
    baseUrl,
    setAdditionalInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    answerEligibilibityQuestion: (payload) =>
      dispatch({ type: `ANSWER_ELIGIBILITY_QUESTION_UNIVERSAL`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EligibiltyQuestionsRaw);
